import ApiClient from '@core/api/client'
import MaintenancePage from '@core/components/MaintenancePage'
import Clarity from '@core/components/vendor/Clarity'
import config from '@core/config'
import { ApiProvider } from '@core/hooks/useApi'
import { isMaintenanceTimeInEST, uniqueId } from '@core/utils'
import { Provider as RollbarProvider } from '@rollbar/react'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { RouterProvider } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import './index.css'
import { createAppRouter } from './routes'

declare global {
  interface Window {
    clarity: (command: string, ...args: any[]) => void
    webkit?: {
      messageHandlers?: {
        webViewPreventBackInterface?: {
          postMessage: (message: string) => void
        }
        webViewProvideCloseButtonInterface?: {
          postMessage: (message: string) => void
        }
      }
    }
    AndroidPreventBackInterface?: {
      enable: () => void
    }
    AndroidProvideCloseButtonInterface?: {
      enable: (dialogTitle: string | null, dialogMessage: string | null) => void
      disable: () => void
    }
    AndroidShareInterface?: {
      share: (title: string, text: string) => void
    }
  }
}

const api = new ApiClient({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  useFaPhotoProxy: import.meta.env.VITE_USE_FA_PHOTO_PROXY === 'true',
})

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.VITE_ENVIRONMENT,
}

const {
  familyAlbumAutoLoginUrl,
  familyAlbumDownloadDeepLink,
  supportEmail,
  familyAlbumStoreDeepLink,
  clarityProjectId,
  isPrintifyPreviewLogsActive,
} = config

function Root() {
  const market = useSearchParam('market')
  const uuid = useSearchParam('uuid')
  const sid = uniqueId()

  const [session] = useState({
    market,
    uuid,
    sid,
  })

  const router = createAppRouter({
    supportEmail,
    isPrintifyPreviewLogsActive,
    familyAlbumDownloadDeepLink,
    familyAlbumStoreDeepLink,
    familyAlbumAutoLoginUrl,
  })

  useEffect(() => {
    if (!clarityProjectId) {
      console.log('No project ID provided for Clarity')
      return
    }

    if (uuid && window.clarity) {
      window.clarity('identify', uuid, sid)
    }
  }, [])

  return (
    <RollbarProvider config={rollbarConfig}>
      {clarityProjectId && <Clarity projectId={clarityProjectId} />}
      <Toaster position="top-center" />
      <ApiProvider value={{ api, session }}>
        <RouterProvider router={router} />
      </ApiProvider>
    </RollbarProvider>
  )
}

if (isMaintenanceTimeInEST()) {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<MaintenancePage />)
} else {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<Root />)
}
