import { MouseEventHandler } from 'react'

const ErrorPage = ({
  error,
  message,
  onButtonClick,
  buttonText,
}: {
  message?: string
  error?: any
  onButtonClick?: MouseEventHandler
  buttonText?: string
}) => {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <div className="text-center space-y-2 px-4">
        <div className="text-4xl font-bold">{error || 'Unknown error'}</div>
        <p className="text-fa-text-secondary">{message}</p>
        {onButtonClick && (
          <button onClick={onButtonClick} className="bg-fa-primary text-white px-4 py-2 rounded-md">
            {buttonText || 'Retry'}
          </button>
        )}
      </div>
    </div>
  )
}

export default ErrorPage
