import { Navigate, useParams } from 'react-router-dom'

export const RedirectRefer = () => {
  const { advocateReferralCode } = useParams()
  return <Navigate to={`/referral/refer/${advocateReferralCode}`} replace />
}

export const RedirectAdvocateRedemption = () => {
  const { advocateReferralCode, redemptionId } = useParams()
  return (
    <Navigate
      to={`/referral/advocate/${advocateReferralCode}/redemptions/${redemptionId}`}
      replace
    />
  )
}

export const RedirectAdvocateRedemptionPast = () => {
  const { advocateReferralCode, redemptionId } = useParams()
  return (
    <Navigate
      to={`/referral/advocate/${advocateReferralCode}/redemptions/${redemptionId}/past`}
      replace
    />
  )
}

export const RedirectR = () => {
  const { advocateReferralCode } = useParams()
  return <Navigate to={`/referral/r/${advocateReferralCode}`} replace />
}

export const RedirectR0 = () => {
  const { advocateReferralCode } = useParams()
  return <Navigate to={`/referral/r0/${advocateReferralCode}`} replace />
}
