/* eslint-disable no-console */
import axios from 'axios'
import Axios, { AxiosInstance, AxiosResponse } from 'axios'
import { PhotoProductsCartApi } from './fagl-server/PhotoProductsCartApi'
import { PhotoProductsApi } from './fagl-server/PhotoProducts'
import { CuratedPhoto, CuratedPhotoForPhotoProducts, DesignTemplate } from './fagl-server/types'

export interface FamilyAlbumPhotoSummaryResponseFamily {
  id: number
  name: string
  relationName: string
  thumbnails: {
    all: string | null
    recommended: string | null
    favorite: string | null
  }
  counts: {
    all: number
    recommended: number
    favorite: number
  }
}

export type FamilyAlbumPhotoSummaryResponse = FamilyAlbumPhotoSummaryResponseFamily[]

export type MediaFile = {
  uuid: string
  url: string
  width: number
  height: number
  tookAt: string
}

export enum FaglFamilyAlbumMediaFilter {
  ALL = 'all',
  FAVORITE = 'favorite',
  RECOMMENDED = 'recommended',
}

export interface FamilyAlbumMediaFilesResponse {
  hasNext: boolean
  cursor: string
  mediaFiles: MediaFile[]
}

export interface ReferralStateForReferredUser {
  error:
    | ''
    | 'INVALID_REFERRAL_INVITE'
    | 'CANNOT_REFER_YOURSELF'
    | 'ALREADY_REDEEMED_ONCE'
    | 'MUST_BE_ALBUM_OWNER'
    | 'FIRST_ADMIN_ALBUM_CREATED_PRIOR_TO_PROMOTION'
    | 'REFERRED_LIMIT_REACHED'
    | 'INVALID_REFERRAL_INVITE'
  deliveryUrl: string | null
  valid: boolean
  reward: number
  rewardCurrencyCode: string
  referredUserUuid: string
  advocateUserUuid: string
  existingRedemption: null | {
    amount: number
    provider: 'FAMILY_ALBUM' | 'RUNA'
    deliveryUrl: string
  }
}

interface AlreadyClaimedRedemption {
  id: number
  value: number
}

export interface AlreadyClaimedRedemptionWithRunaGiftCard extends AlreadyClaimedRedemption {
  deliveryUrl: string
}

export interface AlreadyClaimedRedemptionWithFamilyAlbumGiftCard extends AlreadyClaimedRedemption {
  redemptionCode: string
}

export interface GetAdvocateRedemptionStateResponse {
  friendsReferredNum: number
  maxReferralsNum: number
  remainingReferralsNum: number
  rewardAvailableToClaim: number
  alreadyClaimedRedemptions: {
    runa: AlreadyClaimedRedemptionWithRunaGiftCard[]
    familyAlbum: AlreadyClaimedRedemptionWithFamilyAlbumGiftCard[]
  }
  advocateReward: number
  referredReward: number
  rewardCurrencyCode: string
}

export const fetchAsBlob = (url: string) =>
  axios
    .get(url, {
      withCredentials: true,
      responseType: 'blob',
    })
    .then((response) => response.data)

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(blob)
  })

export class ApiClient {
  api: AxiosInstance
  baseURL: string = ''

  photoProducts: PhotoProductsApi

  photoProductsCart: PhotoProductsCartApi

  useFaPhotoProxy = false

  constructor({ baseURL, useFaPhotoProxy }: { baseURL: string; useFaPhotoProxy: boolean }) {
    this.baseURL = baseURL || `http://localhost:8001`
    this.useFaPhotoProxy = useFaPhotoProxy

    this.api = Axios.create({
      withCredentials: true,
      baseURL: this.baseURL,
    })

    this.photoProducts = new PhotoProductsApi(this.api)
    this.photoProductsCart = new PhotoProductsCartApi(this.api)
  }

  static getHeaders = () => {
    return {
      'X-FAGL-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'X-FAGL-version': import.meta.env.VITE_VERSION as string,
    }
  }

  async getReferralStateForAdvocateUser() {
    const { data } = await this.api.get<object, AxiosResponse<GetAdvocateRedemptionStateResponse>>(
      '/v1/growth/advocate/redemptions/',
      {}
    )

    return data
  }

  async createReferredRedemption() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const { data } = await this.api.post<object, AxiosResponse<{ deliveryUrl: string }>>(
      '/v1/growth/referred/redemptions',
      { timezone }
    )

    return data
  }

  async getReferralStateForReferredUser() {
    const { data } = await this.api.get<ReferralStateForReferredUser>(
      '/v1/growth/referred/redemptions'
    )

    return data
  }

  async logUserActionForAdvocate(action: string, metadata?: object) {
    await this.api.post<object, object>('/v1/growth/advocate/user-actions', {
      action,
      metadata,
    })
  }

  async logUserActionForReferredOfAdvocate(action: string, metadata?: object) {
    await this.api.post<object, object>('/v1/growth/referred/user-actions', {
      action,
      metadata,
    })
  }

  async logUserAction(action: string, metadata?: object) {
    await this.api.post<object, object>('/v1/growth/user-actions', {
      action,
      metadata,
    })
  }

  async getTransitionToken() {
    const { data } = await this.api.get<object, AxiosResponse<string>>(
      '/v1/growth/transition-token'
    )
    return data
  }

  async loginWithTransitionCode(code: string) {
    const { data } = await this.api.post<object, AxiosResponse<string>>(
      '/v1/growth/transition-token',
      {},
      {
        headers: {
          authorization: code,
        },
      }
    )
    return data
  }

  async authenticateSession() {
    await this.api.get('/v1/growth/authenticate-session')
  }

  async getFamilyAlbumPhotosSummary() {
    const { data } = await this.api.get<FamilyAlbumPhotoSummaryResponse>(
      '/v1/family-album-photos',
      {
        headers: ApiClient.getHeaders(),
      }
    )
    return data
  }

  async getFamilyAlbumPhotosForFamilyId(
    familyAlbumFamilyId: number,
    query: {
      filter?: FaglFamilyAlbumMediaFilter | null
      cursor?: string
    }
  ) {
    const params = {
      filter: query.filter,
    }

    if (query.cursor) {
      Object.assign(params, {
        cursor: query.cursor,
      })
    }

    const { data } = await this.api.get<FamilyAlbumMediaFilesResponse>(
      `/v1/family-album-photos/${familyAlbumFamilyId}`,
      {
        params,
        headers: ApiClient.getHeaders(),
      }
    )
    return data
  }

  getProxyUrl = (url: string, inputSize: 'small', outputSize: 'smartphone') => {
    const newUrl = new URL('/v1/family-album-photos/proxy', this.baseURL)
    newUrl.searchParams.set('url', encodeURIComponent(url))
    newUrl.searchParams.set('inputSize', inputSize)
    newUrl.searchParams.set('outputSize', outputSize)
    return newUrl.toString()
  }

  async convertCdnPhotoToBase64({
    url,
    inputSize = 'small',
    outputSize = 'smartphone',
    useFaPhotoProxy = false,
  }: {
    url: string
    inputSize?: 'small'
    outputSize?: 'smartphone'
    useFaPhotoProxy?: boolean
  }) {
    const urlWithCorrectedSize =
      useFaPhotoProxy ?? this.useFaPhotoProxy
        ? this.getProxyUrl(url, inputSize, outputSize)
        : url.replace(inputSize, outputSize)
    const blob = await fetchAsBlob(urlWithCorrectedSize)
    const dataUrl = await convertBlobToBase64(blob)
    return dataUrl
  }

  async convertCdnPhotosToBase64(
    faPhotos: { url: string; uuid: string }[],
    useFaPhotoProxy = false
  ) {
    const dataUrls = await Promise.all(
      faPhotos.map(async (photo) => {
        const base64 = await this.convertCdnPhotoToBase64({
          url: photo.url,
          useFaPhotoProxy,
        })
        return { ...photo, base64 }
      })
    )
    return dataUrls
  }

  async createPhotoUploadLink({ fileName, fileType }: { fileName: string; fileType: string }) {
    const { data } = await this.api.post<{
      signedRequest: string
      url: string
    }>('/v1/media/growth-product-photo', {
      fileName,
      fileType,
    })

    return data
  }

  static uploadFile = async (url: string, file: File | Blob, fileType: string) => {
    const headers = {
      'Content-Type': fileType,
      // "x-amz-acl": "public-read",
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'PUT,POST,DELETE',
    }

    const response = await axios.put<{
      url: string
    }>(url, file, {
      headers,
    })

    return response.data
  }

  uploadBase64Image = async ({ base64, name }: { base64: string; name: string }) => {
    const fileName = [new Date().getTime(), name.replace(/\s/g, '_')].join('_')
    const blob = await (await fetch(base64 as string)).blob()
    const { signedRequest, url: cdnUrl } = await this.createPhotoUploadLink({
      fileName,
      fileType: blob.type,
    })

    await ApiClient.uploadFile(signedRequest, blob, blob.type)

    return {
      url: cdnUrl,
      base64Data: base64,
    }
  }

  async getFamilyAlbumCuratedPhotos(landscapeOnly: boolean = false) {
    const { data } = await this.api.get<CuratedPhoto[]>('/v1/growth/family-album-curated-photos', {
      headers: ApiClient.getHeaders(),
      params: {
        landscapeOnly,
      },
    })
    return data
  }

  async getCuratedPhotosForPhotoProducts(familyId: number | null) {
    const { data } = await this.api.get<CuratedPhotoForPhotoProducts[]>(
      `/v1/family-album-curated-photo-products-photos${familyId ? `?familyId=${familyId}` : ''}`
    )
    return data
  }

  getDesignTemplatesWithVariantId = async (productVariantId: number) => {
    const { data } = await this.api.get<DesignTemplate[]>(`/v1/design-templates`, {
      params: {
        productVariantId,
      },
    })
    return data
  }
}

export default ApiClient
